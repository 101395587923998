* {
  margin: 0;
  padding: 0;
  font-family: 'Axiforma', sans-serif;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

h1 {
  color: #45454E;
}

p {
  color: #8E8E8E;
}

/* HEADER */
:root {
  --font-size-titulo: 46.5px;
  --font-size-links-nav: 25.16px;
  --border-radius-button-nav: 12px;
  --font-size-textos-principais: 21px; 

  --font-size-textos-container2: 22px;

  --font-size-title-card: 22px;
  --font-size-text-card: 15px;
  --font-size-span-card: 17px;

  --font-siize-title-modal: 30px;
  --font-siize-text-modal: 17px;

  --font-size-title-metodologia: 27px;
  --font-size-text-metodologia: 22px;
  --border-radius-metodologia: 10px;

  --font-size-title-footer: 45px;
  --font-size-p-footer: 25px;
  --font-size-span-footer: 21px;
  --font-size-input-footer: 20px;
  --main-border-radius-footer: 17px;
  --border-radius-footer: 8px;
  --border-radius-button-footer: 14px;

  --color-links-nav: #686872;
  --color-title-cards: #3A3A3A;
  --laranja: #da501f;
  --azul: #525FE1;
}

@media (max-width: 1700px) {
  :root {
    --font-size-links-nav: 20px;
    --font-size-titulo: 37px;

    --font-size-textos-container2: 18px;

    --font-size-title-card: 18px;
    --font-size-text-card: 12px;
    --font-size-span-card: 14px;

    --font-size-title-metodologia: 21px;
    --font-size-text-metodologia: 17px;
    --border-radius-metodologia: 8px;

    --font-size-title-footer: 36px;
    --font-size-p-footer: 20px;
    --font-size-span-footer: 17px;
    --font-size-input-footer: 16px;
    --main-border-radius-footer: 14px;
    --border-radius-footer: 6px;
    --border-radius-button-footer: 10px;

    --border-radius-button-nav: 10px;

  }
}

@media (max-width:1100px){
  :root{
    --font-siize-title-modal: 27px;
    --font-siize-text-modal: 15px;
  }
}

@media (max-width: 700px) {
  :root {
    --font-size-links-nav: 16px;
    --font-size-titulo: 30px;

    --font-size-textos-principais: 18px;

    --font-size-textos-container2: 16px;
    --border-radius-button-nav: 7px;
  }
}

@media (max-width: 550px) {
  :root {
    --font-size-links-nav: 15px;
    --font-size-titulo: 25px;

    --font-size-textos-principais: 15px;

    --font-size-textos-container2: 13px;

    --font-siize-title-modal: 18px;
    --font-siize-text-modal: 13px;

    --font-size-title-metodologia: 18px;
    --font-size-text-metodologia: 14px;
    --border-radius-metodologia: 4px;

    --font-size-title-footer: 21px;
    --font-size-p-footer: 16px;
    --font-size-span-footer: 13px;
    --font-size-input-footer: 12px;
    --main-border-radius-footer: 14px;
    --border-radius-button-footer: 13px;
  }
}

@media (max-width: 380px) {
  :root {
    --font-size-links-nav: 14px;
    --font-size-titulo: 20px;

    --font-size-textos-principais: 13px;

    --font-size-textos-container2: 11px;

    --font-size-title-metodologia: 15px;
    --font-size-text-metodologia: 12px;
    --border-radius-metodologia: 4px;
  }
}

@media (max-width: 350px) {
  :root {
    --font-size-links-nav: 14px;
    --font-size-titulo: 20px;

    --font-size-textos-principais: 13px;

    --font-size-textos-container2: 11px;
  }
}