.principal-container2{
    width: 100%;
    background-color: var(--azul);
    display: flex;
}

.centralizar-container2{
    max-width: 1920px;
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.centralizar-container2 hr{
    height: 60px;
}

.centralizar-container2 div {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 55px 0px 55px 0px;
    color: white;
}

.centralizar-container2 div h2{
    font-size: var(--font-size-titulo);
}

.centralizar-container2 div span{
    font-size: var( --font-size-textos-container2);
}

@media (max-width: 800px) {

    .item-grid1-container2 { grid-area: item1; }
    .item-grid2-container2 { grid-area: item2; }
    .item-grid3-container2 { grid-area: item3; }
    .item-grid4-container2 { grid-area: item4; }

    .hr-grid1-container2 { grid-area: hr1; }
    .hr-grid2-container2 { grid-area: hr2; }
    .hr-grid3-container2 { display: none; }

    .centralizar-container2 hr{
        height: 60px;
    }

     .centralizar-container2{
        display: grid;
        grid-template-areas:
        'item1 hr1 item2'
        'item3 hr2 item4';
    }

    
    .centralizar-container2 hr{
        height: 40px;
    }
}

@media (max-width: 400px) {
    .centralizar-container2 div {
        text-align: center;
        display: flex;
        flex-direction: column;
        padding: 30px 0px 15px 0px;
        color: white;
    }
}