.principal-container3 {
    display: none;
}

@media (max-width: 1100px) {
    .principal-container3 {
        padding-top: 80px;
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .principal-container3 p {
        width: 600px;
        text-align: center;
        margin: auto;
        font-size: var(--font-size-textos-principais);
    }

    .principal-container3>div {
        width: 600px;
        padding-top: 50px;
        margin: auto;
        display: flex;
    }

    .alinha-botoes-container3 {
        margin: auto;
        display: flex;
    }

    .principal-container3 button {
        background-color: var(--laranja);
        border: none;
        font-size: var(--font-size-links-nav);
        padding: 5px 18px 1px 18px;
        color: white;
        border-radius: 13px;
        font-weight: 700;
        margin-left: 50px;
        cursor: pointer;
    }

    .principal-container3 a {
        padding: 7px 0px 0px 10px;
        text-decoration: none;
        font-size: var(--font-size-links-nav);
        color: #686872;
    }


    .circulo-play-container3 {
        cursor: pointer;
        width: 52px;
        height: 52px;
        background-color: var(--azul);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .triangulo-play-container3 {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 12px solid white;
    }
}

@media (max-width: 700px) {
    .principal-container3 {
        padding-top: 60px;
    }

    .principal-container3 p {
        width: 80%;
    }

    .principal-container3>div {
        width: 80%;
        padding-top: 30px;
    }

    .principal-container3 button {
        margin-left: 30px;
        border-radius: 6px;
    }

    .circulo-play-container3 {
        width: 40px;
        height: 40px;
    }

    .triangulo-play-container3 {
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 8px solid white;
        margin-left: 2px;
    }
}

@media (max-width: 550px) {
    .principal-container3 button {
        padding: 3px 18px 3px 18px;
    }

}

@media (max-width: 450px) {
    .principal-container3 p {
        width: 95%;
    }

    .principal-container3>div {
        width: 98%;
    }

    .principal-container3 button {
        font-size: 12px;
        padding: 2px 6px 0px 6px;
        margin-left: 10px;
    }

    .circulo-play-container3 {
        width: 35px;
        height: 35px;
    }

    .triangulo-play-container3 {
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 8px solid white;
        margin-left: 2px;
    }

    .principal-container3 a {
        padding: 9px 0px 0px 5px;
    }
}