.principal-carrossel-estrutura {
    width: 100%;
    padding-top: 220px;
    max-width: 1920px;
    margin: auto;
}

.principal-carrossel-estrutura .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    display: initial;
    z-index: 0;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    color: var(--laranja);
    font-size: 20px !important;
    font-weight: bolder;
}

:root {
    --swiper-theme-color: var(--laranja) !important;
}

.principal-carrossel-estrutura .swiper-slide {
    width: 550px !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    top: 480px !important;
    left: 40% !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
    top: 480px !important;
    right: 40% !important;
}

.principal-carrossel-estrutura h1 {
    text-align: center;
    font-size: var(--font-size-titulo);
}

.principal-carrossel-estrutura h1 span {
    color: var(--laranja);
}

.principal-carrossel-estrutura>p {
    font-size: var(--font-size-textos-principais);
    padding-top: 35px;
    text-align: center;
    margin: auto;
    width: 920px;
}

.div-carrossel-estrutura {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alinha-carrossel-estrutura {
    width: 500px;
}

.alinha-carrossel-estrutura img {
    margin-top: 100px;
    width: 100%;
    border-radius: 10px;
}

@media (max-width: 1700px) {

    .principal-carrossel-estrutura .swiper-slide {
        width: 400px !important;
    }

    .alinha-carrossel-estrutura {
        width: 400px;
    }

    .alinha-carrossel-estrutura img {
        border-radius: 8px;
    }
}

@media (max-width: 1350px) {
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        top: 487px !important;
    }
    
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
        top: 487px !important;
    }
}

@media (max-width: 1100px) {
    .principal-carrossel-estrutura>p {
        width: 80%;
    }

    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        left: 30% !important;
    }

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
        right: 30% !important;
    }
}

@media (max-width: 700px) {
    .principal-carrossel-estrutura {
        padding-top: 100px;
    }

    .principal-carrossel-estrutura .swiper-slide {
        width: 300px !important;
    }

    .alinha-carrossel-estrutura {
        width: 300px;
    }

    .alinha-carrossel-estrutura img {
        border-radius: 4px;
    }

    .div-carrossel-estrutura {
        height: 400px;
    }

    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        display: none !important;
    }

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
        display: none !important;
    }
}
