.responsive-container4 {
    display: none;
}

.responsive-container4 .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    display: none;
}

.swiper-container {
    width: 480px;
}

@media (max-width: 1350px) {
    .responsive-container4 {
        margin: auto;
        display: flex;
        padding-top: 100px;
        justify-content: space-around;
    }

    .div-cards-container4 {
        flex-direction: column;
        width: 290px;
        height: 380px;
        box-shadow: 0px 3.05671px 3.05671px rgba(212, 212, 212, 0.88), inset 0px 3.05671px 0px rgba(212, 212, 212, 0.69);
        display: flex;
        border-radius: 9.17013px;
    }

    .alinha-cards-container4 {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .div-cards-container4 div img {
        width: 100%;
    }

    .div-cards-container4 div h2 {
        padding: 20px 0px 0px 19px;
        color: var(--color-title-cards);
        font-size: var(--font-size-title-card);
    }

    .div-cards-container4 div p {
        padding: 0px 20px 0px 19px;
        font-size: var(--font-size-text-card);
    }

    .div-cards-container4 div span {
        color: var(--laranja);
        padding: 0px 0px 0px 19px;
        font-size: var(--font-size-span-card);
        cursor: pointer;
    }

    .swiper-backface-hidden .swiper-slide{
        padding: 5px;
    }
}

@media screen and (min-width: 768px) {
    .swiper-container {
        width: 768px;
    }
}


@media screen and (min-width: 640px) {
    .swiper-container {
        width: 640px;
    }
}


@media (max-width: 500px) {
    .swiper-container {
        width: 500px;
    }

    
    .alinha-cards-container4 {
        text-align: center;
    }

    .div-cards-container4 div h2 {
        padding: 20px 0px 0px 0px;
        color: var(--color-title-cards);
        font-size: var(--font-size-title-card);
    }

    .div-cards-container4 div p {
        padding: 0px;
    }

    .div-cards-container4 div span {
        padding: 0px;
    }

    .div-cards-container4 {
        width: 220px;
        height: 300px;
    }

    .div-cards-container4 div h2 {
        padding-top: 10px;
        color: var(--color-title-cards);
        font-size: var(--font-size-title-card);
    }

    .div-cards-container4 div p {
        padding: 0px;
        font-size: var(--font-size-text-card);
    }

    .div-cards-container4 div span {
        color: var(--laranja);
        padding: 0px;
        font-size: var(--font-size-span-card);
        cursor: pointer;
    }
}