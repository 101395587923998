.principal-carrossel-metodologia {
    width: 100%;
    padding-top: 220px;
    padding-bottom: 250px;
    margin: auto;
    max-width: 1920px;
}

.principal-carrossel-metodologia .swiper-button-prev,
.principal-carrossel-metodologia .swiper-rtl .swiper-button-next {
    top: 580px !important;
    left: 40% !important;
}

.principal-carrossel-metodologia .swiper-button-next,
.principal-carrossel-metodologia .swiper-rtl .swiper-button-prev {
    top: 580px !important;
    right: 40% !important;
}

.principal-carrossel-metodologia .swiper-slide {
    width: 800px !important;
}

.principal-carrossel-metodologia h1 {
    text-align: center;
    font-size: var(--font-size-titulo);
}

.principal-carrossel-metodologia h1 span {
    color: var(--laranja);
}

.principal-carrossel-metodologia>p {
    font-size: var(--font-size-textos-principais);
    padding-top: 35px;
    text-align: center;
    margin: auto;
    width: 920px;
}

.alinha-carrossel-metodologia{
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.alinha-carrossel-metodologia h1{
    margin-top: 85px;
    text-align: start;
    width: 740px;
    padding: 25px 0px 18px 60px;
    background-color: var(--laranja);
    color: white;
    font-size: var(--font-size-title-metodologia);
    border-radius: var(--border-radius-metodologia);
}

.alinha-carrossel-metodologia p {
    width: 722px;
    padding: 45px 18px 0px 60px;
    box-shadow: 0px 5.03123px 5.03123px rgba(0, 0, 0, 0.07), inset 0px 5.03123px 5.03123px rgba(199, 199, 199, 0.08);
    margin-top: 15px;
    height: 235px;
    border-radius: var(--border-radius-metodologia);
    font-size: var(--font-size-text-metodologia);
    margin-bottom: 50px;
}

@media (max-width: 1700px) {
    .principal-carrossel-metodologia .swiper-slide {
        width: 650px !important;
    }

    .alinha-carrossel-metodologia h1{
        width: 650px;
        padding: 20px 0px 14px 35px;
    }
    
    .alinha-carrossel-metodologia p {
        width: 630px;
        padding: 35px 15px 0px 35px;
        height: 220px;
    }

}

@media (max-width: 1100px) {
    .principal-carrossel-metodologia>p {
        width: 80%;
    }
}

@media (max-width: 800px) {
    .principal-carrossel-metodologia {
        display: none;
    }
}