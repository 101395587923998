.principal-modalCards {
    width: 100%;
    height: 100vh;
    align-items: center;
    display: flex;
    overflow-y: scroll;
}

.principal-modalCards>div {
    width: 1100px;
    margin: auto;
    height: 650px;
    display: flex;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16), inset 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.principal-modalCards>div img {
    height: 100%;
}

.principal-modalCards button {
    margin: 50px 0px 0px 90%;
    background-color: white;
    border: none;
}

.principal-modalCards h1 {
    color: #3A3A3A;
    padding-left: 50px;
    font-size: var(--font-siize-title-modal);
}

.principal-modalCards p {
    color: #7D7D7D;
    text-align: justify;
    padding: 10px 70px 0px 50px;
    font-size: var(--font-siize-text-modal);
}

.principal-modalCards ul {
    color: #7D7D7D;
    text-align: justify;
    padding-right: 70px;
    padding-top: 15px;
    padding-left: 50px;
    font-size: var(--font-siize-text-modal);
    list-style: none;
}

@media (max-width: 1100px) {
    .principal-modalCards>div {
        width: 80%;
        align-items: center;
    }

    .principal-modalCards>div img {
        display: none;
    }

    .button-x-modalcard img{
        display: flex !important;
    }

    .principal-modalCards button {
        margin: 30px 0px 0px 85%;
    }

}

@media (max-width: 700px) {
    .principal-modalCards>div {
        height: auto;
        min-height: 80vh;
    }
    
    .principal-modalCards p {
        padding: 10px 70px 30px 50px;
    }
}

@media (max-width: 550px) {

    .principal-modalCards>div {
        width: 90%;
    }

    .principal-modalCards h1 {
        padding-left: 20px;
    }

    .principal-modalCards p {
        padding: 2px 20px 0px 20px;
    }

    .principal-modalCards ul {
        padding-right: 30px;
        padding-left: 30px;
    }

    .principal-modalCards ul {
        padding-left: 20px;
    }

}
