.principal-footer {
    width: 100%;
    display: flex;
    background-color: var(--azul);
}

.principal-footer > div{
    width: 100%;
    display: flex;
    margin: auto;
    max-width: 1920px;
}


.alinha-endereco-footer {
    display: flex;
    flex-direction: column;
    padding: 110px 0px 110px 240px;
}

.alinha-endereco-footer h1 {
    width: 430px;
    font-family: 'Inter', sans-serif;
    font-size: var(--font-size-title-footer);
    color: white;
    padding-bottom: 45px;
}

.alinha-endereco-footer p {
    width: 600px;
    font-family: 'Inter', sans-serif;
    color: white;
    font-size: var(--font-size-p-footer);
    padding-bottom: 130px;
}

.alinha-endereco-footer span {
    font-family: 'Inter', sans-serif;
    color: white;
    font-size: var(--font-size-span-footer);
    padding-bottom: 40px;
}

.alinha-endereco-footer div {
    padding-top: 240px;
}

.alinha-endereco-footer div a {
    color: white;
}

.insta-footer,
.face-footer,
.youtube-footer {
    margin-left: 50px;
}

.whats-footer,
.insta-footer {
    font-size: 40px;
}

.face-footer {
    font-size: 38px;
}

.youtube-footer {
    font-size: 45px;
}

.alinha-form-footer {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.principal-footer form {
    border-radius: var(--main-border-radius-footer);
    width: 480px;
    padding: 45px 45px 45px 45px;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.principal-footer form span {
    font-size: var(--font-size-span-footer);
    color: #7E7E7E;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
}

.principal-footer form input {
    border-radius: var(--border-radius-footer);
    padding: 15px 0px 15px 20px;
    font-size: var(--font-size-input-footer);
    font-family: 'Inter', sans-serif;
    border: 1px solid #D7D2D2;
}

::placeholder {
    color: #D8D8D8;
}

.principal-footer textarea {
    resize: none;
    border-radius: var(--border-radius-footer);
    padding: 15px 0px 0px 20px;
    height: 235px;
    font-size: var(--font-size-input-footer);
    border: 1px solid #D7D2D2;
}

.margin-top {
    margin-top: 36px;
}

.principal-footer form button {
    background-color: var(--laranja);
    border: none;
    font-size: var(--font-size-span-footer);
    color: white;
    border-radius: var(--border-radius-button-footer);
    margin-top: 33px;
    width: 200px;
    height: 54px;
    cursor: pointer;
}

@media (max-width: 1700px) {
    .alinha-endereco-footer {
        padding: 75px 0px 100px 100px;
    }

    .alinha-endereco-footer h1 {
        width: 344px;
        padding-bottom: 35px;
    }

    .alinha-endereco-footer p {
        width: 470px;
        padding-bottom: 100px;
    }

    .alinha-endereco-footer span {
        padding-bottom: 30px;
    }

    .alinha-endereco-footer div {
        padding-top: 200px;
    }

    .insta-footer,
    .face-footer,
    .youtube-footer {
        margin-left: 40px;
    }

    .whats-footer,
    .insta-footer {
        font-size: 37px;
    }

    .face-footer {
        font-size: 35px;
    }

    .youtube-footer {
        font-size: 42px;
    }

    form {
        width: 480px;
        padding: 38px 38px 30px 38px;
    }

    form button {
        margin-top: 25px;
        width: 160px;
        height: 45px;
    }

}

@media (max-width: 1200px) {
    .principal-footer {
        display: none;
    }
}