.principal-footer-responsive {
    display: none;
}

@media (max-width: 1200px) {
    .principal-footer-responsive {
        padding-top: 100px;
        display: flex;
        width: 100%;
        flex-direction: column;
        background-color: var(--azul);
    }

    .principal-footer-responsive h1 {
        width: 80%;
        font-family: 'Inter', sans-serif;
        font-size: var(--font-size-title-footer);
        color: white;
        padding-bottom: 80px;
        text-align: center;
        margin: auto;
    }

    .principal-footer-responsive p {
        width: 80%;
        font-family: 'Inter', sans-serif;
        color: white;
        font-size: var(--font-size-p-footer);
        padding-bottom: 100px;
        text-align: center;
        margin: auto;
    }

    .alinha-form-footer-responsive {
        display: flex;
        width: 100%;
        background-color: white;
    }

    .principal-footer-responsive form {
        margin: auto;
        width: 80%;
        flex-direction: column;
        display: flex;
    }


    .principal-footer-responsive form span {
        font-size: var(--font-size-span-footer);
        color: #7E7E7E;
        font-family: 'Inter', sans-serif;
        padding-bottom: 20px;
        text-align: left;
    }

    .principal-footer-responsive .margin-top {
        margin-top: 25px;
    }

    .principal-footer-responsive form input {
        border-radius: var(--border-radius-footer);
        padding: 15px 0px 15px 20px;
        font-size: var(--font-size-input-footer);
        font-family: 'Inter', sans-serif;
        border: 1px solid #D7D2D2;
    }

    .principal-footer-responsive textarea {
        resize: none;
        border-radius: var(--border-radius-footer);
        padding: 15px 0px 0px 20px;
        height: 200px;
        font-size: var(--font-size-input-footer);
        border: 1px solid #D7D2D2;
    }

    .principal-footer-responsive form button {
        background-color: var(--laranja);
        border: none;
        font-size: var(--font-size-span-footer);
        color: white;
        border-radius: var(--border-radius-button-footer);
        width: 200px;
        height: 54px;
        margin: 30px auto;
        cursor: pointer;
    }

    .principal-footer-responsive span {
        text-align: center;
        font-family: 'Inter', sans-serif;
        color: white;
        font-size: var(--font-size-span-footer);
        padding-bottom: 40px;
    }

    .alinha-links-footer-responsive {
        margin: auto;
        padding: 60px 0px;
        width: 80%;
        display: flex;
        justify-content: space-around;
    }

    .alinha-links-footer-responsive a {
        color: white;
    }

    .alinha-links-footer-responsive .whats-footer,
    .alinha-links-footer-responsive .insta-footer {
        font-size: 36px;
    }

    .alinha-links-footer-responsive .face-footer {
        font-size: 34px;
    }

    .alinha-links-footer-responsive .youtube-footer {
        font-size: 41px;
    }

}

@media (max-width: 550px) {
    .alinha-links-footer-responsive .whats-footer,
    .alinha-links-footer-responsive .insta-footer {
        font-size: 30px;
    }

    .alinha-links-footer-responsive .face-footer {
        font-size: 28px;
    }

    .alinha-links-footer-responsive .youtube-footer {
        font-size: 35px;
    }
}