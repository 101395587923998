.principal-container1 {
    width: 100vw;
    max-width: 1920px;
    margin: auto;
    display: flex; 
}

.centraliza-container1 {
    width: 90%;
    margin: auto;
    justify-content: space-around;
    display: flex;
    padding-top: 80px;
}

.esquerda-container1 {
    width: 570px;
}

.esquerda-container1 h1{
    padding-top: 80px;
    font-size: var(--font-size-titulo);
}

.esquerda-container1 p {
    width: 460px;
    text-align: justify;
    font-size: var(--font-size-textos-principais);
    padding-top: 30px;
}

.esquerda-container1 > div{
    display: flex;
    align-items: center;
    padding-top: 72px;
    margin-bottom: 150px;
}

.esquerda-container1 > div button{
    background-color: var(--laranja);
    border: none;
    font-size: var(--font-size-links-nav);
    padding: 12px 24px 6px 24px;
    color: white;
    border-radius: 13px;
    margin-right: 42px;
    font-weight: 700;
    cursor: pointer;
}

.esquerda-container1 a{
    padding: 7px 0px 0px 10px;
    text-decoration: none;
    font-size: var(--font-size-links-nav);
    color: #686872;
}

.circulo-play-container1 {
    cursor: pointer;
    width: 52px;
    height: 52px;
    background-color: var(--azul);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.triangulo-play-container1 {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid white;
}

.direita-container1 {
    width: 800px;
    display: flex;
    position: relative;
}

.direita-container1 img{
    margin-left: 15%;
    width: 700px;
    position: absolute;
    bottom: 0;
}

@media (max-width: 1700px) {
    .direita-container1 img{
        width: 550px;
    }

    .centraliza-container1 {
        padding-top: 20px;
    }

    .esquerda-container1 > div{
        display: flex;
        align-items: center;
        padding-top: 72px;
        margin-bottom: 100px;
    }
}

@media (max-width: 1200px) {
    .direita-container1 img{
        margin-left: 5%;
    }
}

@media (max-width: 1100px) {

    .esquerda-container1{
        margin: auto;
        text-align: center;
    }

    .esquerda-container1 h1{
        padding-top: 50px;
    }

    .esquerda-container1 p {
        display: none;
    }

    .esquerda-container1 > div{
        display: none;
    }

    .centraliza-container1 {
       flex-direction: column;
    }

    .direita-container1{
        padding-top: 30px;
        position: inherit;
        margin: auto;
    }

    .direita-container1 img{
        position: inherit;
        margin: auto;
        width: 600px;
    }
}

@media (max-width: 900px) {
    .direita-container1 {
        width: 100%;
    }

    .direita-container1 img{
        width: 500px;
    }
}

@media (max-width: 700px) {
    .esquerda-container1 {
        width: 100%;
    }

    .direita-container1 img{
        width: 400px;
    }
}

@media (max-width: 550px) {
    .esquerda-container1 h1{
        padding-top: 15px;
    }

    .direita-container1 img{
        width: 300px;
    }
}