.principal-container4 {
    width: 90%;
    padding-top: 170px;
    max-width: 1920px;
    margin: auto;
}

.principal-container4 h1 {
    text-align: center;
    font-size: var(--font-size-titulo);
}

.principal-container4 h1 span {
    color: var(--laranja);
}

.principal-container4>p {
    font-size: var(--font-size-textos-principais);
    padding-top: 35px;
    text-align: center;
    margin: auto;
    width: 920px;
}

.cards-container4 {
    padding-top: 100px;
    display: flex;
    justify-content: space-around;
}

.responsive-container4 {
    display: none;
}

.cards-container4>div {
    flex-direction: column;
    width: 290px;
    height: 380px;
    box-shadow: 0px 3.98367px 3.98367px rgba(212, 212, 212, 0.88), inset 0px 3.98367px 0px rgba(212, 212, 212, 0.69);
    border-radius: 11.951px;
    display: flex;
}

.alinha-cards-container4 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.cards-container4 div img {
    width: 100%;
}

.cards-container4 div h2 {
    padding: 20px 0px 0px 19px;
    color: var(--color-title-cards);
    font-size: var(--font-size-title-card);
}

.cards-container4 div p {
    padding: 0px 20px 0px 19px;
    font-size: var(--font-size-text-card);
}

.cards-container4 div span {
    color: var(--laranja);
    padding: 0px 0px 0px 19px;
    font-size: var(--font-size-span-card);
    cursor: pointer;
}

@media (max-width: 1700px) {
    .cards-container4>div {
        width: 230px;
        height: 310px;
    }
}

@media (max-width: 1350px) {
    .cards-container4 {
        display: none;
    }
}

@media (max-width: 1100px) {
    .principal-container4>p {
        width: 90%;
    }

}

@media (max-width: 700px) {
    .principal-container4 {
        padding-top: 100px;
    }

}