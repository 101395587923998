.principal-header{
    width: 100vw;
    max-width: 1920px;
    height: 120px;
    display: flex;
    margin: auto;
}

.centraliza-header{
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.centraliza-header img{
    width: 300px;
    margin-top: 20px;
}

.centraliza-header nav{
    margin-top: 25px;
}

.centraliza-header nav a{
    text-decoration: none;
    font-size: var(--font-size-links-nav);
    color: var(--color-links-nav);
    font-weight: 400px;
    cursor: pointer;
}

.margin-link-header{
    margin: 0px 35px;
}

.centraliza-header button{
    background-color: var(--laranja);
    border: none;
    font-size: var(--font-size-links-nav);
    padding: 12px 24px 6px 24px;
    color: white;
    border-radius: 13px;
    margin: 25px 100px 0px 100px;
    font-weight: 700;
    cursor: pointer;
}

@media (max-width: 1700px) {
    .centraliza-header{
        width: 95%;
    }   

    .centraliza-header img{
        width: 310px;
    }

    .centraliza-header button{
        margin: 25px 50px 0px 50px;
        padding: 11px 20px 7px 20px;
    }
}

@media (max-width: 1100px) {

    .centraliza-header{
        justify-content: space-around;
    }

    .centraliza-header img{
        width: 350px;
    }

    .centraliza-header nav{
        display: none;
    }
}

@media (max-width: 700px) {
    .centraliza-header img{
        width: 300px;
    }

    .centraliza-header button{
        margin: 20px 20px 0px 0px;
        padding: 9px 15px 5px 15px;
    }
}

@media (max-width: 550px) {
    .centraliza-header{
        width: 100%;
        height: 100px;
    }   

    .centraliza-header img{
        width: 200px;
        margin-top: 10px;
    }

    .centraliza-header button{
        margin: 10px 0px 0px 0px;
        padding: 6px 10px 3px 10px;
    }
}