.principal-carrossel-metodologia-responsive {
    display: none;
}

@media (max-width: 800px) {
    .principal-carrossel-metodologia-responsive {
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        padding-bottom: 250px;
    }

    .principal-carrossel-metodologia-responsive h1 {
        text-align: center;
        font-size: var(--font-size-titulo);
    }

    .principal-carrossel-metodologia-responsive span {
        color: var(--laranja);
    }

    .principal-carrossel-metodologia-responsive>p {
        font-size: var(--font-size-textos-principais);
        padding-top: 35px;
        text-align: center;
        margin: auto;
        width: 80%;
    }

    .alinha-carrossel-metodologia-responsive h1{
        margin: auto;
        margin-top: 85px;
        text-align: start;
        width: 80%;
        padding: 25px 18px 18px 60px;
        background-color: var(--laranja);
        color: white;
        font-size: var(--font-size-title-metodologia);
        border-radius: var(--border-radius-metodologia);
    }
    
    .alinha-carrossel-metodologia-responsive p {
        margin: auto;
        width: 80%;
        padding: 45px 18px 0px 60px;
        box-shadow: 0px 5.03123px 5.03123px rgba(0, 0, 0, 0.07), inset 0px 5.03123px 5.03123px rgba(199, 199, 199, 0.08);
        height: 235px;
        border-radius: var(--border-radius-metodologia);
        font-size: var(--font-size-text-metodologia);
        margin-bottom: 50px;
    }
}

@media (max-width: 550px) {
    .principal-carrossel-metodologia-responsive {
        padding-bottom: 150px;
    }

    .principal-carrossel-metodologia-responsive>p {
        margin-bottom: 50px;
    }
    
    .alinha-carrossel-metodologia-responsive h1{
        margin: auto;
        text-align: center;
        padding: 11px 10px 9px 10px;
    }
    
    .alinha-carrossel-metodologia-responsive p {
        height: 200px;
        padding: 20px 10px 0px 10px;
        margin: 0px auto 20px auto;
        text-align: center;
    }
}